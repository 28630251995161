











































import { TurnoQuery, TurnoRequest } from '@/entidades/KTB/RegistroInformacion/Turno';
import TurnoAperturaResponse from '@/entidades/KTB/Turno/AperturaTurno';
import TurnoAperturaRequest from '@/entidades/KTB/Turno/AperturaTurnoRequest';
import moment from 'moment';
import {Vue,Component,Prop} from 'vue-property-decorator';
import {State} from 'vuex-class';
@Component({
    name:'OpenApertura'
})
export default class OpenApertura extends Vue 
{
    @Prop({type:Boolean,required:false}) open!:boolean;
    @Prop({type:Object,required:false}) item!:TurnoAperturaResponse;
    @State('turno',{namespace:'maestroKtb'}) turno!:Array<TurnoQuery>
    turnoId:number =0;
    fechaInicio:string="";
    wipInicial:number =0;
    wipFinal:number=0;

    get mensaje()
    {
        if(this.open)
        {
            return "ABRIR TURNO";
        }
        else
        {
            return "CERRAR TURNO";
        }
    }

    guardar()
    {
        var turnoRquest = new TurnoAperturaRequest();
        turnoRquest.turnoId = this.turnoId;
        turnoRquest.wipInicial = this.wipInicial;
        turnoRquest.wipFinal = this.wipFinal;
        turnoRquest.aperturaTurnoId = this.item != undefined ? this.item.aperturaTurnoId : 0;
        this.$emit('guardar',turnoRquest);
    }

    mounted() {
      if(this.item != undefined)
      {
          this.turnoId = this.item.turnoId;
          this.fechaInicio = moment(this.item.fechaApertura).format("YYYY-MM-DD");
          this.wipInicial = this.item.wipInicial;
      }
      else
      {
        this.fechaInicio = moment(new Date()).format("YYYY-MM-DD");   
      }
    }
}
